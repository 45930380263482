<template>
  <div class="wrap">
    <Navbar :title="$t('My.PersonalInformation.portrait.SetAvatar')"></Navbar>
    <!-- <van-uploader v-model="imgFile" :result-type="SubmitEvent"></van-uploader> -->
    <div class="header">
      <van-image round width="3.75rem" height="3.75rem" :src="verConfig.url + DataArr.url">
        <template v-slot:error>
          <img width="100%" height="100%" :src="DataArr.url" />
        </template>
      </van-image>
      <span class="button" @click="SubmitEvent()">{{
        $t('My.PersonalInformation.portrait.button')
      }}</span>
    </div>
    <div class="content">
      <div class="content-item" v-for="index in 39" :key="index">
        <van-image round
          @click="ImgEvent('/uploads/system/userimg_kt/userimg_' + index + '.png')"
          :src="verConfig.url + 'uploads/system/userimg_kt/userimg_' + index + '.png'" />
      </div>
    </div>
  </div>
</template>

<script>
import ImgData from '@/utils/ImgData'
import { REQUEST_API } from '@/http/api'
import { getUserInfoNew, editAvatar, GET_USER_INFP, EDIT_AVATAR } from '@/api'
import Navbar from "@/components/JNav";
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Image,
  Toast,
  Uploader
} from 'vant'

export default {
  name: 'Portrait',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader,
    Navbar
  },
  data() {
    return {
      DataArr: {
        url: '',
        icon: '', // 传递给后台的
      },
      ImgArr: ImgData, // img图片资源
      userInfo: {}
    }
  },
  methods: {
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo((res) => {
        Toast.clear();
        this.userInfo = res
        if (res.userimg) {
          this.DataArr.url = res.userimg;
        } else {
          this.DataArr.url = require('@/assets/Img/myImg/head.png')
        }
      }, (err) => {
        Toast.clear();
      });
    },
    imgUrlToFile(url) {
      var self = this;
      var imgLink = url;
      var tempImage = new Image();
      tempImage.onload = function () {
        var base64 = self.getBase64Image(tempImage);
        var imgblob = self.base64toBlob(base64);
        var filename = self.getFileName(self.ruleForm.coverUrl);
        self.ruleForm.coverFile = self.blobToFile(imgblob, filename);
      };
      tempImage.src = imgLink;
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    // 图片的点击事件
    ImgEvent(url) {
      this.DataArr.icon = url
      this.DataArr.url = url
      this.imgFile = url
    },
    SubmitEvent() {
      EDIT_AVATAR({ userimg: this.imgFile }).then(res => {
        Toast(res.msg);
        GET_USER_INFP().then((r) => {
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.userInfo = r.data.data;
          }
        }).catch((e) => {
        });
      }).catch(err => {
        const { msg } = err
        Toast(msg)
      })
    }
  },
  created() {
    this.getUserInfo();
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;

  .header-One {
    opacity: 0;
    width: 100%;
    height: 7.8rem;
  }

  .header {
    width: 100%;
    height: 7.8rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 50px;
    }

    .van-image {
      margin-bottom: 0.7rem;
    }

    >.button {
      padding: 0.25rem 1.3rem;
      border: 1px solid #fff;
      border-radius: 0.35rem;
      color: var(--light);
      font-size: 0.875rem;
      background: var(--red);
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--light);
    gap: calc(10rem / 16);
    padding: calc(10rem / 16);

    >.content-item {
      width: calc((100% - 4 * (10rem / 16)) / 5);
      aspect-ratio: 1;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/ .van-image {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
    }
  }
}
</style>